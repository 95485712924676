/* CSS */
.auto-scroll-container {
    /* Set a fixed width for the container to show only 4 cards initially */
    width: calc(
      4 * (200px + 10px)
    ); /* Assuming each card has a width of 200px and 10px margin */
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
    display: flex;
    gap: 5px;
    /* position: relative; */
  }
  
  /* Apply styles to each option card */
  .auto-scroll-container > * {
    flex-shrink: 0;
    width: 200px;
  }
  .dropbox-content {
    /* Set the maximum width for the text content */
    max-width: 200px; /* Maximum width for the text content */
  }
  
  /* Apply styles to the <p> element inside .dropbox-content */
  .dropbox-content p {
    /* Enable word wrapping within the maximum width or clip the text */
    white-space: normal; /* Use "nowrap" to clip the text instead of wrapping */
    word-wrap: break-word;
  }
  
  .arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    cursor: pointer;
    margin: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  
  .arrow-button.right {
    right: 0;
  }
  
  .arrow-button.left.disabled,
  .arrow-button.right.disabled {
    visibility: hidden;
  }
  
  @media (max-width: 1024px) {
    .auto-scroll-container {
      width: calc(3 * (220px + 1px));
    }
    .arrow-button {
      top: 71%;
    }
  }
  @media (max-width: 768px) {
    .auto-scroll-container {
      width: calc(1 * (280px + 1px));
    }
    .arrow-button {
      top: 71%;
    }
  }
  