.image-size{
    height: 200px;
    width: 200px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.image-size img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}