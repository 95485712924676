.multiselect-container {
  min-height: 44px;
  border: 1px solid var(--bs-gray-100);
}

.multiselect-search-box {
  min-height: 44px;
  font-weight: 500;
  font-size: 1.1rem;
}
