.col-lg-2 .alt-value:hover,
.col-lg-2.alt-value:hover {
    background-color: black;
    color: white;
    transform: scale(1.5);
    max-width: none;
    position:absolute;
    transition: transform 0.3s ease; /* Optional: Add a transition effect for smooth scaling */
}

.col-lg-2 .alt-value,
.col-lg-2.alt-value {
    max-width: 13ch;
   
}


.col-lg-2 .alt-value .add,
.col-lg-2.alt-value .add {
    display: none;
}

.col-lg-2 .alt-value:hover .add,
.col-lg-2.alt-value:hover .add {
    display: block;
    /* Add any additional styles for the button when it's visible */
}