.newtons-cradle {
    --uib-size: 50px;
    --uib-speed: 1.2s;
    --uib-color: #6c63ff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--uib-size);
    height: var(--uib-size);
   }
   
   .newtons-cradle__dot {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 25%;
    transform-origin: center top;
   }
   
   .newtons-cradle__dot::after {
    content: '';
    display: block;
    width: 100%;
    height: 25%;
    border-radius: 50%;
    background-color: var(--uib-color);
   }
   
   .newtons-cradle__dot:first-child {
    animation: swing var(--uib-speed) linear infinite;
   }
   
   .newtons-cradle__dot:last-child {
    animation: swing2 var(--uib-speed) linear infinite;
   }
   
   @keyframes swing {
    0% {
     transform: rotate(0deg);
     animation-timing-function: ease-out;
    }
   
    25% {
     transform: rotate(70deg);
     animation-timing-function: ease-in;
    }
   
    50% {
     transform: rotate(0deg);
     animation-timing-function: linear;
    }
   }
   
   @keyframes swing2 {
    0% {
     transform: rotate(0deg);
     animation-timing-function: linear;
    }
   
    50% {
     transform: rotate(0deg);
     animation-timing-function: ease-out;
    }
   
    75% {
     transform: rotate(-70deg);
     animation-timing-function: ease-in;
    }
   }

   .table-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.9); Adjust opacity here */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Make sure this is above the table rows */
  }
  