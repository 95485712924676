.searchWrapper {
  border: 1px solid #e1e3ea;
  padding: 0.5rem !important;
}
.searchWrapper .chip {
  background: #e1e3ea;
  color: #009ef7;
  border-radius: 0.425rem;
  padding: 0.5rem !important;
  margin-bottom: 6px;
  position: relative;
}
.searchWrapper .chip .closeIcon {
  height: 25px;
  margin-left: 0.5rem !important;
  width: 25px;
}
.singleChip i {
  display: block !important;
}
.icon_down_dir:before {
  content: none;
  display: none;
}
