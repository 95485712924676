#RFS-StepButton {
  width: 5em;
  height: 5em;
  background-color: #009ef7;
}
#RFS-Label {
  font-size: 18px;
}
#RFS-ConnectorContainer{
  top: calc((5em - 1px) / 2);
  left: calc((-50% + 2em) - -7px);
  right: calc((50% + 2em) - -7px);
}
#RFS-StepButton.active span{
  border: 3px solid white;
    height: 4em;
    width: 4em;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}