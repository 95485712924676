.stepper.stepper-pills.stepper-column .stepper-nav {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.stepper.stepper-pills.stepper-column .stepper-nav::after {
  position: absolute;
  content: "";
  /* background-color: #009ef7; */
  background-color: #e1e3ea;
  height: 3px;
  width: 95%;
  z-index: -1;
  top: 21px;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.stepper.stepper-pills.stepper-column .stepper-wrapper {
  display: block;
}
.stepper.stepper-pills.stepper-column .stepper-icon {
  margin: 0 auto;
}
.stepper.stepper-pills .stepper-item .stepper-label {
  text-align: center;
  margin-top: 20px;
}
.stepper.stepper-pills.stepper-column .stepper-item{z-index: unset;}