
  
  .listForMapping {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    /* margin: 6em 100px; */
  }
  
  .cardForMapping {
    width: 49%;
    position: relative;
    /* height: 400px; */
    border-radius: 16px;
    border: 1px solid rgb(204, 214, 221);
    /* overflow: hidden; */
    background-color: #fff;
    margin-top: 40px;
  }

  .cardForMapping .action-icons{
    position: absolute;
    right: 5px;
    top: -20px;
  }
  .cardForMapping .action-icons button + button{
    margin-left: 10px;
  }
  
  .imageForMapping {
    margin: 0;
  }
  
  .image img {
    width: 100%;
    display: block;
  }
  
  .infoForMapping {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    background: transparent;
    padding: 17px;
    padding-top: 30px;
    text-align: center;
    
  }
  
  .infoFormMapping > * {
    margin-bottom: 3px;
  }
  
  h1 {
    font-size: 1rem;
  }
  
  p {
    font-size: 14px;
  }
  
  a {
    text-decoration: none;
  }
  
  .linkForMapping {
    font-size: 0.8rem;
    color: grey;
  }