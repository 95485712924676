.tooltip {
    position: absolute;
    z-index: 9;
    background-color: black;
    color: white;
    border-radius: 5px;
    padding: 5px;
    opacity: 1;
    /* visibility: hidden; */
    transition: opacity 0.3s;
    top: -40px;
    left: 0;
    width: 140px;
    text-align: center;
  }
  
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
  
  .tooltip.show {
    opacity: 1;
    visibility: visible;
  }
  