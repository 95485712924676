/* App.css */

/* .slide-in {
    animation: slide-in 0.5s ease-in-out forwards;
}

.slide-out {
    animation: slide-out 0.5s ease-in-out forwards;
    overflow: hidden;
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
} */

.fade-in {
    opacity: 0;
    animation: fadeInAnimation 0.5s forwards;
    animation-delay: 0.5s;
}

.fade-out {
    opacity: 1;
    animation: fadeOutAnimation 0.5s forwards;
    animation-delay: 0.5s;
}

@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOutAnimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


