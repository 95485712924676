.my-carousel .carousel-slider .control-dots {
  display: none;
}
.my-carousel {
  position: relative;
  width: 49%;
}
.my-carousel .image-container {
  height: 500px;
  width: 82%;
  margin-left: 92px;
  border: 2px solid #393945;
  padding: 10px;
  border-radius: 10px;
}
.my-carousel .image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.my-carousel .carousel.carousel-slider .control-arrow {
  display: none;
}
.my-carousel .carousel .thumbs-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 50px;
}
.my-carousel .carousel + .carousel {
  position: absolute;
  top: 0;
}
.my-carousel .carousel .thumb + .thumb {
  margin-top: 10px;
}
.my-carousel .carousel .thumbs {
  flex-direction: column;
  display: flex;
  padding-left: 0;
}
.my-carousel .carousel .thumb {
  height: 70px;
  width: 70px!important;
}
.my-carousel .carousel .thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.my-carousel .carousel-status {
  display: none;
}
.carousel-content {
  width: 49%;
}
.carousel-content h3 {
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 10px;
}

.carousel-content h4,
.carousel-content h5,
.carousel-content h6 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.carousel-content span {
  font-weight: bold;
}

.carousel-content p {
  margin-bottom: 10px;
}

.carousel-content .description {
  font-style: italic;
  color: #666666;
  font-size: 15px;
}

.carousel-content h2 {
  font-size: 32px;
  font-weight: bold;
  margin-top: 20px;
}

.carousel-content .badge {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
}

.carousel-content .badge-info {
  background-color: #17a2b8;
  color: #ffffff;
}
.my-carousel .nav-btns button{
  background: transparent;
  border: none;
  padding: 0;
}
.my-carousel .nav-btns .prev-btn, .next-btn{
position: absolute;
left: 25px;
}
.my-carousel .nav-btns .prev-btn{top: 0;}
.my-carousel .nav-btns .next-btn{bottom: 10px;}


